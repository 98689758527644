import useApi, {type ApiWrapper} from '~/service/api/_useApi';
import {Ref} from 'vue';
import {
  CashSimulationFormInput,
  MortgageSimulationFormInput
} from '~/models/simulation/simpleSimulation/SimpleSimulationInput';
import type {SimpleOffer} from '~/models/simulation/simpleSimulation/SimpleOffer';
import {ProductType, type SimulationProductType} from '~/enums/ProductType';
import {createQuery} from "~/service/Utils";
import {CreditWorthinessInput, CreditWorthinessWithOffers} from "~/models/simulation/CreditWorthiness";
import {RefinanceConfig, RefinanceInfo, RefinanceInfoDto, RefinanceResponse} from "~/models/simulation/Refinance";
import {SafeLoanResponse, SafeLoanInput} from "~/models/simulation/SafeLoan";
import { CalculatorInput, FlatForStartResponse } from '~/models/simulation/FlatForStart';

type FetchSimulation = {
 [ProductType.MORTGAGE]: (simulationInput: Partial<MortgageSimulationFormInput>) => ApiWrapper<Ref<SimpleOffer[]>>;
 [ProductType.CASH]: (simulationInput: CashSimulationFormInput) => ApiWrapper<Ref<SimpleOffer[]>>;
}

type SimulationSnapshot = {
  createdAt: Date
} & PartialRecord<SimulationProductType, {
  response: SimpleOffer[],
  request: {
    amount: number,
    period: number,
    hypothecValue?: number,
  }
}>


export default function useSimpleSimulationApi<T extends ProductType = ProductType.MORTGAGE>(productType: ProductType = ProductType.MORTGAGE) {
  const {lendiApiBaseUrl, lendiUrl,} = useRuntimeConfig().public;
  const backendSuffix = `/api/simplified-simulation/`;

  const baseUrl = `${lendiApiBaseUrl}`;
  const {FETCH,POST,GET,} = useApi({baseUrl,});

  const fetchCreditWorthiness = async(config: CreditWorthinessInput): Promise<CreditWorthinessWithOffers> => {
    return await POST<CreditWorthinessWithOffers>(`backend/api/creditworthiness`, config);
  };

  const getRefinanceInfo = async(): Promise<RefinanceInfo> => {
    const response = await GET<RefinanceInfoDto>('api/v2/refinances');
    return new RefinanceInfo(response[0]);
  };
  const getRefinanceOffers = async(config: RefinanceConfig): Promise<RefinanceResponse> => {
    return await POST<RefinanceResponse>(`backend/api/refinancing-calculation`, config);
  };

  const getSafeLoanResponse = async(safeLoanInput: SafeLoanInput): Promise<SafeLoanResponse> => {
    return await POST<SafeLoanResponse>('backend/api/safe-loan-calculation', safeLoanInput);
  };

  const getFlatForStartResponse = async(flatForStartInput: CalculatorInput): Promise<FlatForStartResponse> => {
    return await POST<FlatForStartResponse>('backend/api/home-start-calculation', flatForStartInput);
  };

  const fetchSimpleSimulation: FetchSimulation = {
    [ProductType.MORTGAGE]: async(simulationInput: Partial<MortgageSimulationFormInput>): ApiWrapper<Ref<SimpleOffer[]>> => {
      const backendPrefix =  `backend${backendSuffix}`;
      const query = createQuery({
        loanPurposeActivity: simulationInput.loanPurposeActivity,
        collateral: simulationInput.collateral,
        hypothecValue: simulationInput.hypothecValue,
        market: simulationInput.market,
        offerId: simulationInput.offerId,
      });
      return FETCH<SimpleOffer[]>(
        `${backendPrefix}${simulationInput.loanAmount ?? simulationInput.hypothecValue - simulationInput.savings}/${simulationInput.loanPeriod}?${query}`,
        undefined,
        undefined
    );
    },
    [ProductType.CASH]: async(cashSimulationFormType: CashSimulationFormInput): ApiWrapper<Ref<SimpleOffer[]>> => {
      const backendPrefix =  `cash-backend${backendSuffix}`;
      return FETCH<SimpleOffer[], SimpleOffer[]>(`${backendPrefix}${cashSimulationFormType.loanAmount}/${cashSimulationFormType.loanPeriod}`);
    },
  };

  const fetchMortgageSimulation = async(loanAmount: number, loanPeriod: number, query?: string): ApiWrapper<Ref<SimpleOffer[]>> => {
    const backendPrefix =  `backend${backendSuffix}`;
    return FETCH<SimpleOffer[]>(`${backendPrefix}${loanAmount}/${loanPeriod}?${query}`);
  };

  const getSimulationSnapshot = async(simulationUuid: string): Promise<SimulationSnapshot> => {
    const response = await FETCH<SimulationSnapshot>(`api/v2/clients/simplified-simulation/${simulationUuid}`);
    const snapshot = response.data.value;
    if(snapshot.productTypeMortgage) {
      snapshot.productTypeMortgage.response =
        JSON.parse(snapshot.productTypeMortgage.response as any as string);
      snapshot.productTypeMortgage.request =
        JSON.parse(snapshot.productTypeMortgage.request as any as string);
    }
    if(snapshot.productTypeCash) {
      snapshot.productTypeCash.response =
        JSON.parse(snapshot.productTypeCash.response as any as string);
      snapshot.productTypeCash.request =
        JSON.parse(snapshot.productTypeCash.request as any as string);
    }
    return snapshot;
  };

  return {
    fetchSimpleSimulation,
    fetchCreditWorthiness,
    getRefinanceOffers,
    getRefinanceInfo,
    fetchMortgageSimulation,
    getSafeLoanResponse,
    getFlatForStartResponse,
    getSimulationSnapshot,
  };
}
