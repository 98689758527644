import {assign} from "lodash-es";

export class RefinanceConfig {
    actualCapital: number = 250_000;
    margin: number  = 2;
    referenceRate: number | null = null;
    interestRate: number | null = null;
    fixedInterestRate: number | null = null;
    lastInstallmentDate: string | null = "2052-12-10T10:15:30.000Z";
}

export class RefinanceInfoDto {
    id!: number;
    name!: string;
    referenceRate!: number;
    interestRate!: number;
    interestRateUpdatedAt!: string;
    fixedInterestRate!: number;
    offerExpireAt!: string;
}
export class RefinanceInfo extends RefinanceInfoDto {
    constructor(refinanceInfoDto?: RefinanceInfoDto) {
        super();
        if (refinanceInfoDto) {
            assign(this, refinanceInfoDto);
        }
    }
}


export interface RefinanceOffer {
    interestSum: number,
    monthlyInstallment: number,
    loanPeriod: number,
    margin: number,
    benefit: number
}
export class RefinanceResponse {
    currentMortgage: RefinanceOffer | null = null;
    refinanceLowerInstallment: RefinanceOffer | null = null;
    refinanceShorterPeriod: RefinanceOffer | null = null;
}
